import React from "react";
import testimonialDefault from "../images/Testimonialgirl.avif";

const ResponsiveImage = ({ alt, className }) => {
  return (
    <img
      src={testimonialDefault}
      //sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, (max-width: 1280px) 1280px, 100vw"
      alt={alt}
      className={className}
    />
  );
};

export default ResponsiveImage;
