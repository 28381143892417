import React, { useEffect, useState } from "react";
import "../css/Spielkarte.css";

export function Spielkarte() {
  const [randomText, setRandomText] = useState({});
  const [voted, setVoted] = useState(false);
  const [votedIDid, setVotedIDid] = useState(undefined);
  const [votedIDidnt, setVotedIDidnt] = useState(undefined);
  const [votedTotal, setVotedTotal] = useState(undefined);
  const [remainingTexts, setRemainingTexts] = useState([]);

  useEffect(() => {
    document.title = "Ich hab noch nie - Spielkarte";

    // Lade die Texte aus sessionStorage
    const storedTexts = JSON.parse(sessionStorage.getItem("questions"));
    if (storedTexts && storedTexts.length > 0) {
      setRemainingTexts(storedTexts);
      handleRandomize(storedTexts); // Rufe die Funktion auf, um einen zufälligen Text auszuwählen
    }

  }, []);

  useEffect(() => {
    if (votedIDid !== undefined && votedTotal !== undefined) {
      document.getElementById("percentageYes").innerText =
        "Schon gemacht: " + ((votedIDid / votedTotal) * 100).toFixed(2) + "%";
    }
    if (votedIDidnt !== undefined && votedTotal !== undefined) {
      document.getElementById("percentageNo").innerText =
        "Noch nie: " + ((votedIDidnt / votedTotal) * 100).toFixed(2) + "%";
    }
  }, [votedIDid, votedIDidnt, votedTotal]);


  const handleRandomize = (texts = remainingTexts) => {
    if (texts.length === 0) {
      setRandomText({});
      return;
    }

    const randomTextIndex = Math.floor(Math.random() * texts.length);
    setRandomText(texts[randomTextIndex]);

    setVoted(false);
    setVotedIDid(undefined);
    setVotedIDidnt(undefined);
    setVotedTotal(undefined);

    const newRemainingTexts = texts.filter(
      (_, index) => index !== randomTextIndex
    );
    setRemainingTexts(newRemainingTexts);
    sessionStorage.setItem("questions", JSON.stringify(newRemainingTexts));
  };

  function handleVote(iDid) {
    setVoted(true);
    fetch("/api/question/" + randomText.id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ iDid: iDid }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setVotedIDid(data.amountOfIHaveAnswers);
        setVotedIDidnt(data.amountOfIHaventAnswers);
        setVotedTotal(data.total);
      });
  }

  return (
    <>
      <div className="container">
        <div className="card">
          <p id="kartentitel">Ich hab noch nie:</p>
          <p id="kartentext">
            {randomText.question ||
              "Leider waren das alle Fragen. Um unsere Datenbank zu erweitern, schreib uns gerne weitere unter: ich-hab-noch-nie.de@outlook.de"}
          </p>
          <button className="card-button" onClick={() => handleRandomize()}>
            Next
          </button>
        </div>
      </div>
      {randomText.question && (
        <div className="vote-container">
          {!voted ? (
            <>
              <button
                className="ButtonVote Have"
                onClick={() => handleVote(true)}
              >
                Schon gemacht!
              </button>
              <button
                className="ButtonVote Never"
                onClick={() => handleVote(false)}
              >
                Noch nie!
              </button>
            </>
          ) : (
            <div className="result">
              <br />
              <b>So antwortet die Community:</b>
              <br />
              <br />
              <p id="percentageYes" data-value="0%">Schon gemacht: 0%</p>
              <div
                className="ComBalkenJa"
                style={{
                  width:
                    votedTotal > 0
                      ? (votedIDid / votedTotal) * 100 + "%"
                      : "0%",
                }}
              ></div>

<p id="percentageNo" data-value="0%">Noch nie: 0%</p>
              <div
                className="ComBalkenNein"
                style={{
                  width:
                    votedTotal > 0
                      ? (votedIDidnt / votedTotal) * 100 + "%"
                      : "0%",
                }}
              ></div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
