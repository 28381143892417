import React from "react";
import Pictureanchor from "./Pictureanchor";
import Ichhabnochnie from "../images/Ich-hab-noch-nie.avif";
import Beerpong from "../images/Beerpong.avif";
import Kingscup from "../images/Kingscup.avif";
import Ragecage from "../images/Ragecage.avif";
import Flunkyball from "../images/Flunkyball.avif";

export const Pictureanchorbox = () => {
  return (
    <div className="PABox">
      <Pictureanchor
        imageSrc={Flunkyball}
        link="#Flunkyball"
        alttext="Flunkyball Trinkspiel Regeln"
        lable="Flunky-ball"
      />
      <Pictureanchor
        imageSrc={Beerpong}
        link="#Beerpong"
        alttext="Beerpong Spielende"
        lable="Beergpong"
      />
      <Pictureanchor
        imageSrc={Ichhabnochnie}
        link="#NHIE"
        alttext="Ich hab noch nie Spielender"
        lable="Never have I ever"
      />
      <Pictureanchor
        imageSrc={Kingscup}
        link="#Kingscup"
        alttext="Tisch auf dem Kingscup gespielt wird"
        lable="Kingscuup"
      />
      <Pictureanchor
        imageSrc={Ragecage}
        link="#Ragecage"
        alttext="Ein Haufen Ragecage Becher"
        lable="Rage-cage"
      />
    </div>
  );
};

export default Pictureanchorbox;
