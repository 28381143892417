import React, { useEffect } from "react";

export function Datenschutzerklärung() {
  useEffect(() => {
    document.title = "Datenschutzerklärung";
  });
  return (
    <>
      <div>
        <div className="headlinetrinkspiele">
          <h1>Datenschutzerklärung</h1>
          <h2 id="m716">Präambel</h2>
          <p>
            Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
            aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend
            auch kurz als "Daten" bezeichnet) wir zu welchen Zwecken und in
            welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle
            von uns durchgeführten Verarbeitungen personenbezogener Daten,
            sowohl im Rahmen der Erbringung unserer Leistungen als auch
            insbesondere auf unseren Webseiten, in mobilen Applikationen sowie
            innerhalb externer Onlinepräsenzen, wie z. B. unserer
            Social-Media-Profile (nachfolgend zusammenfassend bezeichnet als
            "Onlineangebot").
          </p>
          <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>

          <p>Stand: 1. Februar 2025</p>
          <h2>Inhaltsübersicht</h2>
          <ul class="index">
            <li>
              <a class="index-link" href="#m716">
                Präambel
              </a>
            </li>
            <li>
              <a class="index-link" href="#m3">
                Verantwortlicher
              </a>
            </li>
            <li>
              <a class="index-link" href="#mOverview">
                Übersicht der Verarbeitungen
              </a>
            </li>
            <li>
              <a class="index-link" href="#m2427">
                Maßgebliche Rechtsgrundlagen
              </a>
            </li>
            <li>
              <a class="index-link" href="#m27">
                Sicherheitsmaßnahmen
              </a>
            </li>
            <li>
              <a class="index-link" href="#m25">
                Übermittlung von personenbezogenen Daten
              </a>
            </li>
            <li>
              <a class="index-link" href="#m24">
                Internationale Datentransfers
              </a>
            </li>
            <li>
              <a class="index-link" href="#m12">
                Allgemeine Informationen zur Datenspeicherung und Löschung
              </a>
            </li>
            <li>
              <a class="index-link" href="#m10">
                Rechte der betroffenen Personen
              </a>
            </li>
            <li>
              <a class="index-link" href="#m225">
                Bereitstellung des Onlineangebots und Webhosting
              </a>
            </li>
            <li>
              <a class="index-link" href="#m134">
                Einsatz von Cookies
              </a>
            </li>
            <li>
              <a class="index-link" href="#m182">
                Kontakt- und Anfrageverwaltung
              </a>
            </li>
            <li>
              <a class="index-link" href="#m408">
                Umfragen und Befragungen
              </a>
            </li>
            <li>
              <a class="index-link" href="#m263">
                Webanalyse, Monitoring und Optimierung
              </a>
            </li>
            <li>
              <a class="index-link" href="#m264">
                Onlinemarketing
              </a>
            </li>
            <li>
              <a class="index-link" href="#m328">
                Plug-ins und eingebettete Funktionen sowie Inhalte
              </a>
            </li>
            <li>
              <a class="index-link" href="#m15">
                Änderung und Aktualisierung
              </a>
            </li>
            <li>
              <a class="index-link" href="#m42">
                Begriffsdefinitionen
              </a>
            </li>
          </ul>
          <h2 id="m3">Verantwortlicher</h2>
          <p>
            Linus Stegmann
            <br />
            Anton Saefkow Platz, 8<br />
            10369 Berlin
          </p>
          <p>
            E-Mail-Adresse:
            <a href="mailto:ich-hab-noch-nie.de@outlook.de">
              ich-hab-noch-nie.de@outlook.de
            </a>
          </p>

          <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
          <p>
            Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten
            und die Zwecke ihrer Verarbeitung zusammen und verweist auf die
            betroffenen Personen.
          </p>
          <h3>Arten der verarbeiteten Daten</h3>
          <ul>
            <li>Bestandsdaten.</li>
            <li>Kontaktdaten.</li>
            <li>Inhaltsdaten.</li>
            <li>Nutzungsdaten.</li>
            <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
            <li>Protokolldaten.</li>
          </ul>
          <h3>Kategorien betroffener Personen</h3>
          <ul>
            <li>Kommunikationspartner.</li>
            <li>Nutzer.</li>
            <li>Teilnehmer.</li>
          </ul>
          <h3>Zwecke der Verarbeitung</h3>
          <ul>
            <li>Kommunikation.</li>
            <li>Sicherheitsmaßnahmen.</li>
            <li>Reichweitenmessung.</li>
            <li>Tracking.</li>
            <li>Konversionsmessung.</li>
            <li>Zielgruppenbildung.</li>
            <li>Organisations- und Verwaltungsverfahren.</li>
            <li>Feedback.</li>
            <li>Umfragen und Fragebögen.</li>
            <li>Marketing.</li>
            <li>Profile mit nutzerbezogenen Informationen.</li>
            <li>
              Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
            </li>
            <li>Informationstechnische Infrastruktur.</li>
          </ul>
          <h2 id="m2427">Maßgebliche Rechtsgrundlagen</h2>
          <p>
            <strong>Maßgebliche Rechtsgrundlagen nach der DSGVO: </strong>Im
            Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der
            DSGVO, auf deren Basis wir personenbezogene Daten verarbeiten. Bitte
            nehmen Sie zur Kenntnis, dass neben den Regelungen der DSGVO
            nationale Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder
            Sitzland gelten können. Sollten ferner im Einzelfall speziellere
            Rechtsgrundlagen maßgeblich sein, teilen wir Ihnen diese in der
            Datenschutzerklärung mit.
          </p>
          <ul>
            <li>
              <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> -
              Die betroffene Person hat ihre Einwilligung in die Verarbeitung
              der sie betreffenden personenbezogenen Daten für einen
              spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
            </li>
            <li>
              <strong>
                Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S.
                1 lit. b) DSGVO)
              </strong>
              - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
              Vertragspartei die betroffene Person ist, oder zur Durchführung
              vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
              betroffenen Person erfolgen.
            </li>
            <li>
              <strong>
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)
              </strong>{" "}
              - die Verarbeitung ist zur Wahrung der berechtigten Interessen des
              Verantwortlichen oder eines Dritten notwendig, vorausgesetzt, dass
              die Interessen, Grundrechte und Grundfreiheiten der betroffenen
              Person, die den Schutz personenbezogener Daten verlangen, nicht
              überwiegen.
            </li>
          </ul>
          <p>
            <strong>Nationale Datenschutzregelungen in Deutschland: </strong>
            Zusätzlich zu den Datenschutzregelungen der DSGVO gelten nationale
            Regelungen zum Datenschutz in Deutschland. Hierzu gehört
            insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener
            Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG).
            Das BDSG enthält insbesondere Spezialregelungen zum Recht auf
            Auskunft, zum Recht auf Löschung, zum Widerspruchsrecht, zur
            Verarbeitung besonderer Kategorien personenbezogener Daten, zur
            Verarbeitung für andere Zwecke und zur Übermittlung sowie
            automatisierten Entscheidungsfindung im Einzelfall einschließlich
            Profiling. Ferner können Landesdatenschutzgesetze der einzelnen
            Bundesländer zur Anwendung gelangen.
          </p>
          <p>
            <strong>Hinweis auf Geltung DSGVO und Schweizer DSG: </strong>Diese
            Datenschutzhinweise dienen sowohl der Informationserteilung nach dem
            Schweizer DSG als auch nach der Datenschutzgrundverordnung (DSGVO).
            Aus diesem Grund bitten wir Sie zu beachten, dass aufgrund der
            breiteren räumlichen Anwendung und Verständlichkeit die Begriffe der
            DSGVO verwendet werden. Insbesondere statt der im Schweizer DSG
            verwendeten Begriffe „Bearbeitung" von „Personendaten",
            "überwiegendes Interesse" und "besonders schützenswerte
            Personendaten" werden die in der DSGVO verwendeten Begriffe
            „Verarbeitung" von „personenbezogenen Daten" sowie "berechtigtes
            Interesse" und "besondere Kategorien von Daten" verwendet. Die
            gesetzliche Bedeutung der Begriffe wird jedoch im Rahmen der Geltung
            des Schweizer DSG weiterhin nach dem Schweizer DSG bestimmt.
          </p>

          <h2 id="m27">Sicherheitsmaßnahmen</h2>
          <p>
            Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
            Berücksichtigung des Stands der Technik, der Implementierungskosten
            und der Art, des Umfangs, der Umstände und der Zwecke der
            Verarbeitung sowie der unterschiedlichen
            Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der
            Rechte und Freiheiten natürlicher Personen geeignete technische und
            organisatorische Maßnahmen, um ein dem Risiko angemessenes
            Schutzniveau zu gewährleisten.
          </p>
          <p>
            Zu den Maßnahmen gehören insbesondere die Sicherung der
            Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
            Kontrolle des physischen und elektronischen Zugangs zu den Daten als
            auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der
            Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben
            wir Verfahren eingerichtet, die eine Wahrnehmung von
            Betroffenenrechten, die Löschung von Daten und Reaktionen auf die
            Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den
            Schutz personenbezogener Daten bereits bei der Entwicklung bzw.
            Auswahl von Hardware, Software sowie Verfahren entsprechend dem
            Prinzip des Datenschutzes, durch Technikgestaltung und durch
            datenschutzfreundliche Voreinstellungen.
          </p>
          <p>
            Sicherung von Online-Verbindungen durch
            TLS-/SSL-Verschlüsselungstechnologie (HTTPS): Um die Daten der
            Nutzer, die über unsere Online-Dienste übertragen werden, vor
            unerlaubten Zugriffen zu schützen, setzen wir auf die
            TLS-/SSL-Verschlüsselungstechnologie. Secure Sockets Layer (SSL) und
            Transport Layer Security (TLS) sind die Eckpfeiler der sicheren
            Datenübertragung im Internet. Diese Technologien verschlüsseln die
            Informationen, die zwischen der Website oder App und dem Browser des
            Nutzers (oder zwischen zwei Servern) übertragen werden, wodurch die
            Daten vor unbefugtem Zugriff geschützt sind. TLS, als die
            weiterentwickelte und sicherere Version von SSL, gewährleistet, dass
            alle Datenübertragungen den höchsten Sicherheitsstandards
            entsprechen. Wenn eine Website durch ein SSL-/TLS-Zertifikat
            gesichert ist, wird dies durch die Anzeige von HTTPS in der URL
            signalisiert. Dies dient als ein Indikator für die Nutzer, dass ihre
            Daten sicher und verschlüsselt übertragen werden.
          </p>

          <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
          <p>
            Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es
            vor, dass diese an andere Stellen, Unternehmen, rechtlich
            selbstständige Organisationseinheiten oder Personen übermittelt
            beziehungsweise ihnen gegenüber offengelegt werden. Zu den
            Empfängern dieser Daten können z. B. mit IT-Aufgaben beauftragte
            Dienstleister gehören oder Anbieter von Diensten und Inhalten, die
            in eine Website eingebunden sind. In solchen Fällen beachten wir die
            gesetzlichen Vorgaben und schließen insbesondere entsprechende
            Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit
            den Empfängern Ihrer Daten ab.
          </p>

          <h2 id="m24">Internationale Datentransfers</h2>
          <p>
            Datenverarbeitung in Drittländern: Sofern wir Daten in einem
            Drittland (d. h., außerhalb der Europäischen Union (EU), des
            Europäischen Wirtschaftsraums (EWR)) verarbeiten oder die
            Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter oder
            der Offenlegung bzw. Übermittlung von Daten an andere Personen,
            Stellen oder Unternehmen stattfindet, erfolgt dies nur im Einklang
            mit den gesetzlichen Vorgaben. Sofern das Datenschutzniveau in dem
            Drittland mittels eines Angemessenheitsbeschlusses anerkannt wurde
            (Art. 45 DSGVO), dient dieser als Grundlage des Datentransfers. Im
            Übrigen erfolgen Datentransfers nur dann, wenn das Datenschutzniveau
            anderweitig gesichert ist, insbesondere durch
            Standardvertragsklauseln (Art. 46 Abs. 2 lit. c) DSGVO),
            ausdrückliche Einwilligung oder im Fall vertraglicher oder
            gesetzlich erforderlicher Übermittlung (Art. 49 Abs. 1 DSGVO). Im
            Übrigen teilen wir Ihnen die Grundlagen der Drittlandübermittlung
            bei den einzelnen Anbietern aus dem Drittland mit, wobei die
            Angemessenheitsbeschlüsse als Grundlagen vorrangig gelten.
            Informationen zu Drittlandtransfers und vorliegenden
            Angemessenheitsbeschlüssen können dem Informationsangebot der
            EU-Kommission entnommen werden:
            <a
              href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de"
              target="_blank"
            >
              https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de.
            </a>
            Im Rahmen des sogenannten „Data Privacy Framework" (DPF) hat die
            EU-Kommission das Datenschutzniveau ebenfalls für bestimmte
            Unternehmen aus den USA im Rahmen der Angemessenheitsbeschlusses vom
            10.07.2023 als sicher anerkannt. Die Liste der zertifizierten
            Unternehmen als auch weitere Informationen zu dem DPF können Sie der
            Website des Handelsministeriums der USA unter
            <a href="https://www.dataprivacyframework.gov/" target="_blank">
              https://www.dataprivacyframework.gov/
            </a>
            (in Englisch) entnehmen. Wir informieren Sie im Rahmen der
            Datenschutzhinweise, welche von uns eingesetzten Diensteanbieter
            unter dem Data Privacy Framework zertifiziert sind.
          </p>

          <h2 id="m12">
            Allgemeine Informationen zur Datenspeicherung und Löschung
          </h2>
          <p>
            Wir löschen personenbezogene Daten, die wir verarbeiten, gemäß den
            gesetzlichen Bestimmungen, sobald die zugrundeliegenden
            Einwilligungen widerrufen werden oder keine weiteren rechtlichen
            Grundlagen für die Verarbeitung bestehen. Dies betrifft Fälle, in
            denen der ursprüngliche Verarbeitungszweck entfällt oder die Daten
            nicht mehr benötigt werden. Ausnahmen von dieser Regelung bestehen,
            wenn gesetzliche Pflichten oder besondere Interessen eine längere
            Aufbewahrung oder Archivierung der Daten erfordern.
          </p>
          <p>
            Insbesondere müssen Daten, die aus handels- oder steuerrechtlichen
            Gründen aufbewahrt werden müssen oder deren Speicherung notwendig
            ist zur Rechtsverfolgung oder zum Schutz der Rechte anderer
            natürlicher oder juristischer Personen, entsprechend archiviert
            werden.
          </p>
          <p>
            Unsere Datenschutzhinweise enthalten zusätzliche Informationen zur
            Aufbewahrung und Löschung von Daten, die speziell für bestimmte
            Verarbeitungsprozesse gelten.
          </p>
          <p>
            Bei mehreren Angaben zur Aufbewahrungsdauer oder Löschungsfristen
            eines Datums, ist stets die längste Frist maßgeblich.
          </p>
          <p>
            Beginnt eine Frist nicht ausdrücklich zu einem bestimmten Datum und
            beträgt sie mindestens ein Jahr, so startet sie automatisch am Ende
            des Kalenderjahres, in dem das fristauslösende Ereignis eingetreten
            ist. Im Fall laufender Vertragsverhältnisse, in deren Rahmen Daten
            gespeichert werden, ist das fristauslösende Ereignis der Zeitpunkt
            des Wirksamwerdens der Kündigung oder sonstige Beendigung des
            Rechtsverhältnisses.
          </p>
          <p>
            Daten, die nicht mehr für den ursprünglich vorgesehenen Zweck,
            sondern aufgrund gesetzlicher Vorgaben oder anderer Gründe
            aufbewahrt werden, verarbeiten wir ausschließlich zu den Gründen,
            die ihre Aufbewahrung rechtfertigen.
          </p>
          <p>
            <strong>
              Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
              Diensten:
            </strong>
          </p>
          <ul class="m-elements">
            <li>
              <strong>Aufbewahrung und Löschung von Daten: </strong>Die
              folgenden allgemeinen Fristen gelten für die Aufbewahrung und
              Archivierung nach deutschem Recht:
              <ul>
                <li>
                  10 Jahre - Aufbewahrungsfrist für Bücher und Aufzeichnungen,
                  Jahresabschlüsse, Inventare, Lageberichte, Eröffnungsbilanz
                  sowie die zu ihrem Verständnis erforderlichen
                  Arbeitsanweisungen und sonstigen Organisationsunterlagen (§
                  147 Abs. 1 Nr. 1 i.V.m. Abs. 3 AO, § 14b Abs. 1 UStG, § 257
                  Abs. 1 Nr. 1 i.V.m. Abs. 4 HGB).
                </li>
                <li>
                  8 Jahre - Buchungsbelege, wie z. B. Rechnungen und
                  Kostenbelege (§ 147 Abs. 1 Nr. 4 und 4a i.V.m. Abs. 3 Satz 1
                  AO sowie § 257 Abs. 1 Nr. 4 i.V.m. Abs. 4 HGB).
                </li>
                <li>
                  6 Jahre - Übrige Geschäftsunterlagen: empfangene Handels- oder
                  Geschäftsbriefe, Wiedergaben der abgesandten Handels- oder
                  Geschäftsbriefe, sonstige Unterlagen, soweit sie für die
                  Besteuerung von Bedeutung sind, z. B. Stundenlohnzettel,
                  Betriebsabrechnungsbögen, Kalkulationsunterlagen,
                  Preisauszeichnungen, aber auch Lohnabrechnungsunterlagen,
                  soweit sie nicht bereits Buchungsbelege sind und
                  Kassenstreifen (§ 147 Abs. 1 Nr. 2, 3, 5 i.V.m. Abs. 3 AO, §
                  257 Abs. 1 Nr. 2 u. 3 i.V.m. Abs. 4 HGB).
                </li>
                <li>
                  3 Jahre - Daten, die erforderlich sind, um potenzielle
                  Gewährleistungs- und Schadensersatzansprüche oder ähnliche
                  vertragliche Ansprüche und Rechte zu berücksichtigen sowie
                  damit verbundene Anfragen zu bearbeiten, basierend auf
                  früheren Geschäftserfahrungen und üblichen Branchenpraktiken,
                  werden für die Dauer der regulären gesetzlichen
                  Verjährungsfrist von drei Jahren gespeichert (§§ 195, 199
                  BGB).
                </li>
              </ul>
            </li>
          </ul>
          <h2 id="m10">Rechte der betroffenen Personen</h2>
          <p>
            Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als
            Betroffene nach der DSGVO verschiedene Rechte zu, die sich
            insbesondere aus Art. 15 bis 21 DSGVO ergeben:
          </p>
          <ul>
            <li>
              <strong>
                Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich
                aus Ihrer besonderen Situation ergeben, jederzeit gegen die
                Verarbeitung der Sie betreffenden personenbezogenen Daten, die
                aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
                Widerspruch einzulegen; dies gilt auch für ein auf diese
                Bestimmungen gestütztes Profiling. Werden die Sie betreffenden
                personenbezogenen Daten verarbeitet, um Direktwerbung zu
                betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
                Verarbeitung der Sie betreffenden personenbezogenen Daten zum
                Zwecke derartiger Werbung einzulegen; dies gilt auch für das
                Profiling, soweit es mit solcher Direktwerbung in Verbindung
                steht.
              </strong>
            </li>
            <li>
              <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das
              Recht, erteilte Einwilligungen jederzeit zu widerrufen.
            </li>
            <li>
              <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine
              Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet
              werden und auf Auskunft über diese Daten sowie auf weitere
              Informationen und Kopie der Daten entsprechend den gesetzlichen
              Vorgaben.
            </li>
            <li>
              <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend
              den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
              betreffenden Daten oder die Berichtigung der Sie betreffenden
              unrichtigen Daten zu verlangen.
            </li>
            <li>
              <strong>
                Recht auf Löschung und Einschränkung der Verarbeitung:
              </strong>{" "}
              Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
              verlangen, dass Sie betreffende Daten unverzüglich gelöscht
              werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben
              eine Einschränkung der Verarbeitung der Daten zu verlangen.
            </li>
            <li>
              <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das
              Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben,
              nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten,
              gängigen und maschinenlesbaren Format zu erhalten oder deren
              Übermittlung an einen anderen Verantwortlichen zu fordern.
            </li>
            <li>
              <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben
              unbeschadet eines anderweitigen verwaltungsrechtlichen oder
              gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer
              Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
              gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts
              des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die
              Verarbeitung der Sie betreffenden personenbezogenen Daten gegen
              die Vorgaben der DSGVO verstößt.
            </li>
          </ul>

          <h2 id="m225">Bereitstellung des Onlineangebots und Webhosting</h2>
          <p>
            Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste
            zur Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die
            IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und
            Funktionen unserer Online-Dienste an den Browser oder das Endgerät
            der Nutzer zu übermitteln.
          </p>
          <ul class="m-elements">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B.
              Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und
              -frequenz, verwendete Gerätetypen und Betriebssysteme,
              Interaktionen mit Inhalten und Funktionen); Meta-, Kommunikations-
              und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
              Identifikationsnummern, beteiligte Personen). Protokolldaten
              (z. B. Logfiles betreffend Logins oder den Abruf von Daten oder
              Zugriffszeiten.).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (z. B.
              Webseitenbesucher, Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
              Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische
              Infrastruktur (Betrieb und Bereitstellung von Informationssystemen
              und technischen Geräten (Computer, Server etc.)).
              Sicherheitsmaßnahmen.
            </li>
            <li>
              <strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend
              Angaben im Abschnitt "Allgemeine Informationen zur
              Datenspeicherung und Löschung".
            </li>
            <li class="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).
            </li>
          </ul>
          <p>
            <strong>
              Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
              Diensten:
            </strong>
          </p>
          <ul class="m-elements">
            <li>
              <strong>
                Bereitstellung Onlineangebot auf gemietetem Speicherplatz:{" "}
              </strong>
              Für die Bereitstellung unseres Onlineangebotes nutzen wir
              Speicherplatz, Rechenkapazität und Software, die wir von einem
              entsprechenden Serveranbieter (auch "Webhoster" genannt) mieten
              oder anderweitig beziehen;
              <span class="">
                <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art.
                6 Abs. 1 S. 1 lit. f) DSGVO).
              </span>
            </li>
            <li>
              <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der
              Zugriff auf unser Onlineangebot wird in Form von sogenannten
              "Server-Logfiles" protokolliert. Zu den Serverlogfiles können die
              Adresse und der Name der abgerufenen Webseiten und Dateien, Datum
              und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
              erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem
              des Nutzers, Referrer URL (die zuvor besuchte Seite) und im
              Regelfall IP-Adressen und der anfragende Provider gehören. Die
              Serverlogfiles können zum einen zu Sicherheitszwecken eingesetzt
              werden, z. B. um eine Überlastung der Server zu vermeiden
              (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
              DDoS-Attacken), und zum anderen, um die Auslastung der Server und
              ihre Stabilität sicherzustellen;
              <span class="">
                <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art.
                6 Abs. 1 S. 1 lit. f) DSGVO).{" "}
              </span>
              <strong>Löschung von Daten:</strong> Logfile-Informationen werden
              für die Dauer von maximal 30 Tagen gespeichert und danach gelöscht
              oder anonymisiert. Daten, deren weitere Aufbewahrung zu
              Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung
              des jeweiligen Vorfalls von der Löschung ausgenommen.
            </li>
            <li>
              <strong>STRATO: </strong>Leistungen auf dem Gebiet der
              Bereitstellung von informationstechnischer Infrastruktur und
              verbundenen Dienstleistungen (z. B. Speicherplatz und/oder
              Rechenkapazitäten);
              <strong>Dienstanbieter:</strong> STRATO AG, Pascalstraße 10,10587
              Berlin, Deutschland;
              <span class="">
                <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art.
                6 Abs. 1 S. 1 lit. f) DSGVO);{" "}
              </span>
              <strong>Website:</strong>
              <a href="https://www.strato.de" target="_blank">
                https://www.strato.de
              </a>
              ;<strong>Datenschutzerklärung:</strong>
              <a href="https://www.strato.de/datenschutz/" target="_blank">
                https://www.strato.de/datenschutz/
              </a>
              . <strong>Auftragsverarbeitungsvertrag:</strong> Wird vom
              Dienstanbieter bereitgestellt.
            </li>
          </ul>
          <h2 id="m134">Einsatz von Cookies</h2>
          <p>
            Unter dem Begriff „Cookies" werden Funktionen, die Informationen auf
            Endgeräten der Nutzer speichern und aus ihnen auslesen, verstanden.
            Cookies können ferner in Bezug auf unterschiedliche Anliegen Einsatz
            finden, etwa zu Zwecken der Funktionsfähigkeit, der Sicherheit und
            des Komforts von Onlineangeboten sowie der Erstellung von Analysen
            der Besucherströme. Wir verwenden Cookies gemäß den gesetzlichen
            Vorschriften. Dazu holen wir, wenn erforderlich, vorab die
            Zustimmung der Nutzer ein. Ist eine Zustimmung nicht notwendig,
            setzen wir auf unsere berechtigten Interessen. Dies gilt, wenn das
            Speichern und Auslesen von Informationen unerlässlich ist, um
            ausdrücklich angeforderte Inhalte und Funktionen bereitstellen zu
            können. Dazu zählen etwa die Speicherung von Einstellungen sowie die
            Sicherstellung der Funktionalität und Sicherheit unseres
            Onlineangebots. Die Einwilligung kann jederzeit widerrufen werden.
            Wir informieren klar über deren Umfang und welche Cookies genutzt
            werden.
          </p>
          <p>
            <strong>
              Hinweise zu datenschutzrechtlichen Rechtsgrundlagen:{" "}
            </strong>
            Ob wir personenbezogene Daten mithilfe von Cookies verarbeiten,
            hängt von einer Einwilligung ab. Liegt eine Einwilligung vor, dient
            sie als Rechtsgrundlage. Ohne Einwilligung stützen wir uns auf
            unsere berechtigten Interessen, die vorstehend in diesem Abschnitt
            und im Kontext der jeweiligen Dienste und Verfahren erläutert sind.
          </p>
          <p>
            <strong>Speicherdauer: </strong>Im Hinblick auf die Speicherdauer
            werden die folgenden Arten von Cookies unterschieden:
          </p>
          <ul>
            <li>
              <strong>
                Temporäre Cookies (auch: Session- oder Sitzungscookies):
              </strong>
              Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer
              ein Onlineangebot verlassen und sein Endgerät (z. B. Browser oder
              mobile Applikation) geschlossen hat.
            </li>
            <li>
              <strong>Permanente Cookies:</strong> Permanente Cookies bleiben
              auch nach dem Schließen des Endgeräts gespeichert. So können
              beispielsweise der Log-in-Status gespeichert und bevorzugte
              Inhalte direkt angezeigt werden, wenn der Nutzer eine Website
              erneut besucht. Ebenso können die mithilfe von Cookies erhobenen
              Nutzerdaten zur Reichweitenmessung Verwendung finden. Sofern wir
              Nutzern keine expliziten Angaben zur Art und Speicherdauer von
              Cookies mitteilen (z. B. im Rahmen der Einholung der
              Einwilligung), sollten sie davon ausgehen, dass diese permanent
              sind und die Speicherdauer bis zu zwei Jahre betragen kann.
            </li>
          </ul>
          <p>
            <strong>
              Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-out): 
            </strong>
            Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit
            widerrufen und zudem einen Widerspruch gegen die Verarbeitung
            entsprechend den gesetzlichen Vorgaben, auch mittels der
            Privatsphäre-Einstellungen ihres Browsers, erklären.
          </p>
          <ul class="m-elements">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Meta-, Kommunikations-
              und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
              Identifikationsnummern, beteiligte Personen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (z. B.
              Webseitenbesucher, Nutzer von Onlinediensten).
            </li>
            <li class="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO). Einwilligung (Art. 6 Abs. 1 S. 1 lit.
              a) DSGVO).
            </li>
          </ul>
          <p>
            <strong>
              Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
              Diensten:
            </strong>
          </p>
          <ul class="m-elements">
            <li>
              <strong>
                Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung:{" "}
              </strong>
              Wir setzen eine Einwilligungs-Management-Lösung ein, bei der die
              Einwilligung der Nutzer zur Verwendung von Cookies oder zu den im
              Rahmen der Einwilligungs-Management-Lösung genannten Verfahren und
              Anbietern eingeholt wird. Dieses Verfahren dient der Einholung,
              Protokollierung, Verwaltung und dem Widerruf von Einwilligungen,
              insbesondere bezogen auf den Einsatz von Cookies und
              vergleichbaren Technologien, die zur Speicherung, zum Auslesen und
              zur Verarbeitung von Informationen auf den Endgeräten der Nutzer
              eingesetzt werden. Im Rahmen dieses Verfahrens werden die
              Einwilligungen der Nutzer für die Nutzung von Cookies und die
              damit verbundenen Verarbeitungen von Informationen, einschließlich
              der im Einwilligungs-Management-Verfahren genannten spezifischen
              Verarbeitungen und Anbieter, eingeholt. Die Nutzer haben zudem die
              Möglichkeit, ihre Einwilligungen zu verwalten und zu widerrufen.
              Die Einwilligungserklärungen werden gespeichert, um eine erneute
              Abfrage zu vermeiden und den Nachweis der Einwilligung gemäß der
              gesetzlichen Anforderungen führen zu können. Die Speicherung
              erfolgt serverseitig und/oder in einem Cookie (sogenanntes
              Opt-In-Cookie) oder mittels vergleichbarer Technologien, um die
              Einwilligung einem spezifischen Nutzer oder dessen Gerät zuordnen
              zu können. Sofern keine spezifischen Angaben zu den Anbietern von
              Einwilligungs-Management-Diensten vorliegen, gelten folgende
              allgemeine Hinweise: Die Dauer der Speicherung der Einwilligung
              beträgt bis zu zwei Jahre. Dabei wird ein pseudonymer
              Nutzer-Identifikator erstellt, der zusammen mit dem Zeitpunkt der
              Einwilligung, den Angaben zum Umfang der Einwilligung (z. B.
              betreffende Kategorien von Cookies und/oder Diensteanbieter) sowie
              Informationen über den Browser, das System und das verwendete
              Endgerät gespeichert wird;
              <span class="">
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                S. 1 lit. a) DSGVO).
              </span>
            </li>
          </ul>
          <h2 id="m182">Kontakt- und Anfrageverwaltung</h2>
          <p>
            Bei der Kontaktaufnahme mit uns (z. B. per Post, Kontaktformular,
            E-Mail, Telefon oder via soziale Medien) sowie im Rahmen bestehender
            Nutzer- und Geschäftsbeziehungen werden die Angaben der anfragenden
            Personen verarbeitet, soweit dies zur Beantwortung der
            Kontaktanfragen und etwaiger angefragter Maßnahmen erforderlich ist.
          </p>
          <ul class="m-elements">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der
              vollständige Name, Wohnadresse, Kontaktinformationen,
              Kundennummer, etc.); Kontaktdaten (z. B. Post- und E-Mail-Adressen
              oder Telefonnummern); Inhaltsdaten (z. B. textliche oder bildliche
              Nachrichten und Beiträge sowie die sie betreffenden Informationen,
              wie z. B. Angaben zur Autorenschaft oder Zeitpunkt der
              Erstellung); Nutzungsdaten (z. B. Seitenaufrufe und Verweildauer,
              Klickpfade, Nutzungsintensität und -frequenz, verwendete
              Gerätetypen und Betriebssysteme, Interaktionen mit Inhalten und
              Funktionen). Meta-, Kommunikations- und Verfahrensdaten (z. B.
              IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
              Personen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Kommunikationspartner.
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Kommunikation;
              Organisations- und Verwaltungsverfahren; Feedback (z. B. Sammeln
              von Feedback via Online-Formular). Bereitstellung unseres
              Onlineangebotes und Nutzerfreundlichkeit.
            </li>
            <li>
              <strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend
              Angaben im Abschnitt "Allgemeine Informationen zur
              Datenspeicherung und Löschung".
            </li>
            <li class="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO). Vertragserfüllung und vorvertragliche
              Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            </li>
          </ul>
          <p>
            <strong>
              Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
              Diensten:
            </strong>
          </p>
          <ul class="m-elements">
            <li>
              <strong>Kontaktformular: </strong>Bei Kontaktaufnahme über unser
              Kontaktformular, per E-Mail oder anderen Kommunikationswegen,
              verarbeiten wir die uns übermittelten personenbezogenen Daten zur
              Beantwortung und Bearbeitung des jeweiligen Anliegens. Dies
              umfasst in der Regel Angaben wie Name, Kontaktinformationen und
              gegebenenfalls weitere Informationen, die uns mitgeteilt werden
              und zur angemessenen Bearbeitung erforderlich sind. Wir nutzen
              diese Daten ausschließlich für den angegebenen Zweck der
              Kontaktaufnahme und Kommunikation;
              <span class="">
                <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
                vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO),
                Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
              </span>
            </li>
          </ul>
          <h2 id="m408">Umfragen und Befragungen</h2>
          <p>
            Wir führen Umfragen und Befragungen durch, um Informationen für den
            jeweils kommunizierten Umfrage- bzw. Befragungszweck, zu sammeln.
            Die von uns durchgeführten Umfragen und Befragungen (nachfolgend
            "Befragungen") werden anonym ausgewertet. Eine Verarbeitung
            personenbezogener Daten erfolgt nur insoweit, als dies zu
            Bereitstellung und technischen Durchführung der Umfragen
            erforderlich ist (z. B. Verarbeitung der IP-Adresse, um die Umfrage
            im Browser des Nutzers darzustellen oder mithilfe eines Cookies eine
            Wiederaufnahme der Umfrage zu ermöglichen).
          </p>
          <ul class="m-elements">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z. B. der
              vollständige Name, Wohnadresse, Kontaktinformationen,
              Kundennummer, etc.); Kontaktdaten (z. B. Post- und E-Mail-Adressen
              oder Telefonnummern); Inhaltsdaten (z. B. textliche oder bildliche
              Nachrichten und Beiträge sowie die sie betreffenden Informationen,
              wie z. B. Angaben zur Autorenschaft oder Zeitpunkt der
              Erstellung). Nutzungsdaten (z. B. Seitenaufrufe und Verweildauer,
              Klickpfade, Nutzungsintensität und -frequenz, verwendete
              Gerätetypen und Betriebssysteme, Interaktionen mit Inhalten und
              Funktionen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Teilnehmer.
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Feedback (z. B. Sammeln
              von Feedback via Online-Formular). Umfragen und Fragebögen (z. B.
              Umfragen mit Eingabemöglichkeiten, Multiple-Choice-Fragen).
            </li>
            <li>
              <strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend
              Angaben im Abschnitt "Allgemeine Informationen zur
              Datenspeicherung und Löschung".
            </li>
            <li class="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).
            </li>
          </ul>
          <p>
            <strong>
              Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
              Diensten:
            </strong>
          </p>
          <ul class="m-elements">
            <li>
              <strong>Google-Formular: </strong>Erstellung und Auswertung von
              Onineformularen, Umfragen, Feedbackbögen, etc;
              <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon
              House, Barrow Street, Dublin 4, Irland;
              <span class="">
                <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art.
                6 Abs. 1 S. 1 lit. f) DSGVO);{" "}
              </span>
              <strong>Website:</strong>
              <a href="https://www.google.de/intl/de/forms" target="_blank">
                https://www.google.de/intl/de/forms
              </a>
              ; <strong>Datenschutzerklärung:</strong>
              <a href="https://policies.google.com/privacy" target="_blank">
                https://policies.google.com/privacy
              </a>
              ; <strong>Auftragsverarbeitungsvertrag:</strong>
              <a
                href="https://cloud.google.com/terms/data-processing-addendum"
                target="_blank"
              >
                https://cloud.google.com/terms/data-processing-addendum
              </a>
              . <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
              Framework (DPF).
            </li>
          </ul>
          <h2 id="m263">Webanalyse, Monitoring und Optimierung</h2>
          <p>
            Die Webanalyse (auch als „Reichweitenmessung" bezeichnet) dient der
            Auswertung der Besucherströme unseres Onlineangebots und kann
            Verhalten, Interessen oder demografische Informationen zu den
            Besuchern, wie beispielsweise Alter oder Geschlecht, als pseudonyme
            Werte umfassen. Mithilfe der Reichweitenanalyse können wir zum
            Beispiel erkennen, zu welcher Zeit unser Onlineangebot oder dessen
            Funktionen beziehungsweise Inhalte am häufigsten genutzt werden,
            oder zur Wiederverwendung einladen. Ebenso ist es uns möglich,
            nachzuvollziehen, welche Bereiche der Optimierung bedürfen.
          </p>
          <p>
            Neben der Webanalyse können wir auch Testverfahren einsetzen, um
            etwa unterschiedliche Versionen unseres Onlineangebots oder seiner
            Bestandteile zu testen und zu optimieren.
          </p>
          <p>
            Sofern nachfolgend nicht anders angegeben, können zu diesen Zwecken
            Profile, also zu einem Nutzungsvorgang zusammengefasste Daten,
            angelegt und Informationen in einem Browser bzw. in einem Endgerät
            gespeichert und dann ausgelesen werden. Zu den erhobenen Angaben
            gehören insbesondere besuchte Websites und dort genutzte Elemente
            sowie technische Auskünfte, wie etwa der verwendete Browser, das
            benutzte Computersystem sowie Angaben zu Nutzungszeiten. Sofern sich
            Nutzer in die Erhebung ihrer Standortdaten uns gegenüber oder
            gegenüber den Anbietern der von uns eingesetzten Dienste
            einverstanden erklärt haben, ist auch die Verarbeitung von
            Standortdaten möglich.
          </p>
          <p>
            Darüber hinaus werden die IP-Adressen der Nutzer gespeichert. Jedoch
            nutzen wir ein IP-Masking-Verfahren (d. h. Pseudonymisierung durch
            Kürzung der IP-Adresse) zum Schutz der Nutzer. Generell werden die
            im Rahmen von Webanalyse, A/B-Testings und Optimierung keine
            Klardaten der Nutzer (wie z. B. E-Mail-Adressen oder Namen)
            gespeichert, sondern Pseudonyme. Das heißt, wir als auch die
            Anbieter der eingesetzten Software kennen nicht die tatsächliche
            Identität der Nutzer, sondern nur die zum Zweck der jeweiligen
            Verfahren in deren Profilen gespeicherten Angaben.
          </p>
          <p>
            Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren
            Einwilligung in den Einsatz der Drittanbieter bitten, stellt die
            Rechtsgrundlage der Datenverarbeitung die Einwilligung dar.
            Ansonsten werden die Nutzerdaten auf Grundlage unserer berechtigten
            Interessen (d. h. Interesse an effizienten, wirtschaftlichen und
            empfängerfreundlichen Leistungen) verarbeitet. In diesem
            Zusammenhang möchten wir Sie auch auf die Informationen zur
            Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
          </p>
          <ul class="m-elements">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B.
              Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und
              -frequenz, verwendete Gerätetypen und Betriebssysteme,
              Interaktionen mit Inhalten und Funktionen). Meta-, Kommunikations-
              und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
              Identifikationsnummern, beteiligte Personen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (z. B.
              Webseitenbesucher, Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung
              (z. B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher);
              Profile mit nutzerbezogenen Informationen (Erstellen von
              Nutzerprofilen). Bereitstellung unseres Onlineangebotes und
              Nutzerfreundlichkeit.
            </li>
            <li>
              <strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend
              Angaben im Abschnitt "Allgemeine Informationen zur
              Datenspeicherung und Löschung". Speicherung von Cookies von bis zu
              2 Jahren (Sofern nicht anders angegeben, können Cookies und
              ähnliche Speichermethoden für einen Zeitraum von zwei Jahren auf
              den Geräten der Nutzer gespeichert werden.).
            </li>
            <li>
              <strong>Sicherheitsmaßnahmen:</strong> IP-Masking
              (Pseudonymisierung der IP-Adresse).
            </li>
            <li class="">
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S.
              1 lit. a) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
              f) DSGVO).
            </li>
          </ul>
          <p>
            <strong>
              Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
              Diensten:
            </strong>
          </p>
          <ul class="m-elements">
            <li>
              <strong>Google Analytics: </strong>Wir verwenden Google Analytics
              zur Messung und Analyse der Nutzung unseres Onlineangebotes auf
              der Grundlage einer pseudonymen Nutzeridentifikationsnummer. Diese
              Identifikationsnummer enthält keine eindeutigen Daten, wie Namen
              oder E-Mail-Adressen. Sie dient dazu, Analyseinformationen einem
              Endgerät zuzuordnen, um zu erkennen, welche Inhalte die Nutzer
              innerhalb eines oder verschiedener Nutzungsvorgänge aufgerufen
              haben, welche Suchbegriffe sie verwendet haben, diese erneut
              aufgerufen haben oder mit unserem Onlineangebot interagiert haben.
              Ebenso werden der Zeitpunkt der Nutzung und deren Dauer
              gespeichert, sowie die Quellen der Nutzer, die auf unser
              Onlineangebot verweisen und technische Aspekte ihrer Endgeräte und
              Browser.
              <br />
              Dabei werden pseudonyme Profile von Nutzern mit Informationen aus
              der Nutzung verschiedener Geräte erstellt, wobei Cookies
              eingesetzt werden können. Google Analytics protokolliert und
              speichert keine individuellen IP-Adressen für EU-Nutzer. Analytics
              stellt jedoch grobe geografische Standortdaten bereit, indem es
              die folgenden Metadaten von IP-Adressen ableitet: Stadt (und der
              abgeleitete Breiten- und Längengrad der Stadt), Kontinent, Land,
              Region, Subkontinent (und ID-basierte Gegenstücke). Beim
              EU-Datenverkehr werden die IP-Adressdaten ausschließlich für diese
              Ableitung von Geolokalisierungsdaten verwendet, bevor sie sofort
              gelöscht werden. Sie werden nicht protokolliert, sind nicht
              zugänglich und werden nicht für weitere Verwendungszwecke genutzt.
              Wenn Google Analytics Messdaten sammelt, werden alle IP-Abfragen
              auf EU-basierten Servern durchgeführt, bevor der Verkehr zur
              Verarbeitung an Analytics-Server weitergeleitet wird;{" "}
              <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon
              House, Barrow Street, Dublin 4, Irland;
              <span class="">
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                S. 1 lit. a) DSGVO);{" "}
              </span>
              <strong>Website:</strong>
              <a
                href="https://marketingplatform.google.com/intl/de/about/analytics/"
                target="_blank"
              >
                https://marketingplatform.google.com/intl/de/about/analytics/
              </a>
              ; <strong>Sicherheitsmaßnahmen:</strong> IP-Masking
              (Pseudonymisierung der IP-Adresse);{" "}
              <strong>Datenschutzerklärung:</strong>
              <a href="https://policies.google.com/privacy" target="_blank">
                https://policies.google.com/privacy
              </a>
              ; <strong>Auftragsverarbeitungsvertrag:</strong>
              <a
                href="https://business.safety.google/adsprocessorterms/"
                target="_blank"
              >
                https://business.safety.google/adsprocessorterms/
              </a>
              ; <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
              Framework (DPF);{" "}
              <strong>Widerspruchsmöglichkeit (Opt-Out):</strong>{" "}
              Opt-Out-Plugin:
              <a
                href="https://tools.google.com/dlpage/gaoptout?hl=de"
                target="_blank"
              >
                https://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              , Einstellungen für die Darstellung von Werbeeinblendungen:
              <a
                href="https://myadcenter.google.com/personalizationoff"
                target="_blank"
              >
                https://myadcenter.google.com/personalizationoff
              </a>
              . <strong>Weitere Informationen:</strong>
              <a
                href="https://business.safety.google/adsservices/"
                target="_blank"
              >
                https://business.safety.google/adsservices/
              </a>
              (Arten der Verarbeitung sowie der verarbeiteten Daten).
            </li>
          </ul>
          <h2 id="m264">Onlinemarketing</h2>
          <p>
            Wir verarbeiten personenbezogene Daten zum Zweck des
            Onlinemarketings, worunter insbesondere die Vermarktung von
            Werbeflächen oder die Darstellung von werbenden und sonstigen
            Inhalten (zusammenfassend als „Inhalte" bezeichnet) anhand
            potenzieller Interessen der Nutzer sowie die Messung ihrer
            Effektivität fallen können.
          </p>
          <p>
            Zu diesen Zwecken werden sogenannte Nutzerprofile angelegt und in
            einer Datei (der sogenannte „Cookie") gespeichert oder ähnliche
            Verfahren genutzt, mittels derer die für die Darstellung der
            vorgenannten Inhalte relevanten Angaben zum Nutzer gespeichert
            werden. Hierzu können beispielsweise betrachtete Inhalte, besuchte
            Websites, genutzte Onlinenetzwerke, aber auch Kommunikationspartner
            und technische Angaben gehören, wie etwa der verwendete Browser, das
            benutzte Computersystem sowie Auskünfte zu Nutzungszeiten und
            genutzten Funktionen. Sofern Nutzer in die Erhebung ihrer
            Standortdaten eingewilligt haben, können auch diese verarbeitet
            werden.
          </p>
          <p>
            Zudem werden die IP-Adressen der Nutzer gespeichert. Jedoch nutzen
            wir zur Verfügung stehende IP-Masking-Verfahren (d. h.
            Pseudonymisierung durch Kürzung der IP-Adresse) zum Nutzerschutz.
            Generell werden im Rahmen des Onlinemarketingverfahrens keine
            Klardaten der Nutzer (wie z. B. E-Mail-Adressen oder Namen)
            gespeichert, sondern Pseudonyme. Das heißt, wir als auch die
            Anbieter der Onlinemarketingverfahren kennen nicht die tatsächliche
            Nutzeridentität, sondern nur die in deren Profilen gespeicherten
            Angaben.
          </p>
          <p>
            Die Aussagen in den Profilen werden im Regelfall in den Cookies oder
            mittels ähnlicher Verfahren gespeichert. Diese Cookies können später
            generell auch auf anderen Websites, die dasselbe
            Onlinemarketingverfahren einsetzen, ausgelesen und zum Zweck der
            Darstellung von Inhalten analysiert sowie mit weiteren Daten ergänzt
            und auf dem Server des Onlinemarketingverfahrensanbieters
            gespeichert werden.
          </p>
          <p>
            Ausnahmsweise ist es möglich, Klardaten den Profilen zuzuordnen,
            vornehmlich dann, wenn die Nutzer zum Beispiel Mitglieder eines
            sozialen Netzwerks sind, dessen Onlinemarketingverfahren wir
            einsetzen und das Netzwerk die Nutzerprofile mit den vorgenannten
            Angaben verbindet. Wir bitten darum, zu beachten, dass Nutzer mit
            den Anbietern zusätzliche Abreden treffen können, etwa durch
            Einwilligung im Rahmen der Registrierung.
          </p>
          <p>
            Wir erhalten grundsätzlich nur Zugang zu zusammengefassten
            Informationen über den Erfolg unserer Werbeanzeigen. Jedoch können
            wir im Rahmen sogenannter Konversionsmessungen prüfen, welche
            unserer Onlinemarketingverfahren zu einer sogenannten Konversion
            geführt haben, d. h. beispielsweise zu einem Vertragsschluss mit
            uns. Die Konversionsmessung wird alleine zur Erfolgsanalyse unserer
            Marketingmaßnahmen verwendet.
          </p>
          <p>
            Solange nicht anders angegeben, bitten wir Sie, davon auszugehen,
            dass eingesetzte Cookies für einen Zeitraum von zwei Jahren
            gespeichert werden.
          </p>
          <p>
            <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer
            um deren Einwilligung in den Einsatz der Drittanbieter bitten,
            stellt die Rechtsgrundlage der Datenverarbeitung die Erlaubnis dar.
            Ansonsten werden die Daten der Nutzer auf Grundlage unserer
            berechtigten Interessen (d. h. Interesse an effizienten,
            wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet.
            In diesem Zusammenhang möchten wir Sie auch auf die Informationen
            zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
          </p>
          <p>
            <strong>Hinweise zum Widerruf und Widerspruch:</strong>
          </p>
          <p>
            Wir verweisen auf die Datenschutzhinweise der jeweiligen Anbieter
            und die zu den Anbietern angegebenen Widerspruchsmöglichkeiten (sog.
            "Opt-Out"). Sofern keine explizite Opt-Out-Möglichkeit angegeben
            wurde, besteht zum einen die Möglichkeit, dass Sie Cookies in den
            Einstellungen Ihres Browsers abschalten. Hierdurch können jedoch
            Funktionen unseres Onlineangebotes eingeschränkt werden. Wir
            empfehlen daher zusätzlich die folgenden Opt-Out-Möglichkeiten, die
            zusammenfassend auf jeweilige Gebiete gerichtet angeboten werden:
          </p>
          <p>
            a) Europa:
            <a href="https://www.youronlinechoices.eu" target="_blank">
              https://www.youronlinechoices.eu.
            </a>
          </p>
          <p>
            b) Kanada:
            <a href="https://www.youradchoices.ca/choices" target="_blank">
              https://www.youradchoices.ca/choices.
            </a>
          </p>
          <p>
            c) USA:
            <a href="https://www.aboutads.info/choices" target="_blank">
              https://www.aboutads.info/choices.
            </a>
          </p>
          <p>
            d) Gebietsübergreifend:
            <a href="https://optout.aboutads.info" target="_blank">
              https://optout.aboutads.info.
            </a>
          </p>
          <ul class="m-elements">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B.
              Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und
              -frequenz, verwendete Gerätetypen und Betriebssysteme,
              Interaktionen mit Inhalten und Funktionen). Meta-, Kommunikations-
              und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
              Identifikationsnummern, beteiligte Personen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (z. B.
              Webseitenbesucher, Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Reichweitenmessung
              (z. B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher);
              Tracking (z. B. interessens-/verhaltensbezogenes Profiling,
              Nutzung von Cookies); Zielgruppenbildung; Marketing; Profile mit
              nutzerbezogenen Informationen (Erstellen von Nutzerprofilen).
              Konversionsmessung (Messung der Effektivität von
              Marketingmaßnahmen).
            </li>
            <li>
              <strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend
              Angaben im Abschnitt "Allgemeine Informationen zur
              Datenspeicherung und Löschung". Speicherung von Cookies von bis zu
              2 Jahren (Sofern nicht anders angegeben, können Cookies und
              ähnliche Speichermethoden für einen Zeitraum von zwei Jahren auf
              den Geräten der Nutzer gespeichert werden.).
            </li>
            <li>
              <strong>Sicherheitsmaßnahmen:</strong> IP-Masking
              (Pseudonymisierung der IP-Adresse).
            </li>
            <li class="">
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S.
              1 lit. a) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
              f) DSGVO).
            </li>
          </ul>
          <p>
            <strong>
              Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
              Diensten:
            </strong>
          </p>
          <ul class="m-elements">
            <li>
              <strong>Google Ads und Konversionsmessung: </strong>
              Online-Marketing-Verfahren zum Zwecke der Platzierung von Inhalten
              und Anzeigen innerhalb des Werbenetzwerks des Diensteanbieters
              (z. B. in Suchergebnissen, in Videos, auf Webseiten usw.), so dass
              sie Nutzern angezeigt werden, die ein mutmaßliches Interesse an
              den Anzeigen haben. Darüber hinaus messen wir die Konversion der
              Anzeigen, d. h. ob die Nutzer sie zum Anlass genommen haben, mit
              den Anzeigen zu interagieren und die beworbenen Angebote zu nutzen
              (sog. Konversionen). Wir erhalten jedoch nur anonyme Informationen
              und keine persönlichen Informationen über einzelne Nutzer;{" "}
              <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon
              House, Barrow Street, Dublin 4, Irland;
              <span class="">
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                S. 1 lit. a) DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                lit. f) DSGVO);{" "}
              </span>
              <strong>Website:</strong>
              <a href="https://marketingplatform.google.com" target="_blank">
                https://marketingplatform.google.com
              </a>
              ; <strong>Datenschutzerklärung:</strong>
              <a href="https://policies.google.com/privacy" target="_blank">
                https://policies.google.com/privacy
              </a>
              ; <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
              Framework (DPF); <strong>Weitere Informationen:</strong> Arten der
              Verarbeitung sowie der verarbeiteten Daten:
              <a
                href="https://business.safety.google/adsservices/"
                target="_blank"
              >
                https://business.safety.google/adsservices/
              </a>
              . Datenverarbeitungsbedingungen zwischen Verantwortlichen und
              Standardvertragsklauseln für Drittlandtransfers von Daten:
              <a
                href="https://business.safety.google/adscontrollerterms"
                target="_blank"
              >
                https://business.safety.google/adscontrollerterms
              </a>
              .
            </li>
            <li>
              <strong>Google Adsense mit personalisierten Anzeigen: </strong>Wir
              binden den Dienst Google Adsense ein, der es ermöglicht,
              personalisierte Anzeigen innerhalb unseres Onlineangebots zu
              platzieren. Google Adsense analysiert das Nutzerverhalten und
              verwendet diese Daten, um zielgerichtete Werbung auszuspielen, die
              auf die Interessen unserer Besucher abgestimmt ist. Für jede
              Anzeigenschaltung oder andere Nutzungsarten dieser Anzeigen
              erhalten wir eine finanzielle Vergütung;{" "}
              <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon
              House, Barrow Street, Dublin 4, Irland;
              <span class="">
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                S. 1 lit. a) DSGVO);{" "}
              </span>
              <strong>Website:</strong>
              <a href="https://marketingplatform.google.com" target="_blank">
                https://marketingplatform.google.com
              </a>
              ; <strong>Datenschutzerklärung:</strong>
              <a href="https://policies.google.com/privacy" target="_blank">
                https://policies.google.com/privacy
              </a>
              ; <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
              Framework (DPF); <strong>Weitere Informationen:</strong> Arten der
              Verarbeitung sowie der verarbeiteten Daten:
              <a
                href="https://business.safety.google/adsservices/"
                target="_blank"
              >
                https://business.safety.google/adsservices/
              </a>
              . Datenverarbeitungsbedingungen für Google Werbeprodukte:
              Informationen zu den Diensten Datenverarbeitungsbedingungen
              zwischen Verantwortlichen und Standardvertragsklauseln für
              Drittlandtransfers von Daten:
              <a
                href="https://business.safety.google/adscontrollerterms"
                target="_blank"
              >
                https://business.safety.google/adscontrollerterms
              </a>
              .
            </li>
            <li>
              <strong>
                Google Adsense mit nicht-personalisierten Anzeigen:{" "}
              </strong>
              Wir nutzen den Dienst Google Adsense, um nicht-personalisierte
              Anzeigen in unserem Onlineangebot zu schalten. Diese Anzeigen
              basieren nicht auf dem individuellen Nutzerverhalten, sondern
              werden anhand allgemeiner Merkmale wie dem Inhalt der Seite oder
              Ihrer ungefähren geografischen Lage ausgewählt. Für die
              Einblendung oder sonstige Nutzung dieser Anzeigen erhalten wir
              eine Vergütung; <strong>Dienstanbieter:</strong> Google Ireland
              Limited, Gordon House, Barrow Street, Dublin 4, Irland;
              <span class="">
                <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1
                S. 1 lit. a) DSGVO);{" "}
              </span>
              <strong>Website:</strong>
              <a href="https://marketingplatform.google.com" target="_blank">
                https://marketingplatform.google.com
              </a>
              ; <strong>Datenschutzerklärung:</strong>
              <a href="https://policies.google.com/privacy" target="_blank">
                https://policies.google.com/privacy
              </a>
              ; <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
              Framework (DPF); <strong>Weitere Informationen:</strong> Arten der
              Verarbeitung sowie der verarbeiteten Daten:
              <a
                href="https://business.safety.google/adsservices/"
                target="_blank"
              >
                https://business.safety.google/adsservices/
              </a>
              . Datenverarbeitungsbedingungen für Google Werbeprodukte:
              Informationen zu den Diensten Datenverarbeitungsbedingungen
              zwischen Verantwortlichen und Standardvertragsklauseln für
              Drittlandtransfers von Daten:
              <a
                href="https://business.safety.google/adscontrollerterms"
                target="_blank"
              >
                https://business.safety.google/adscontrollerterms
              </a>
              .
            </li>
          </ul>
          <h2 id="m328">Plug-ins und eingebettete Funktionen sowie Inhalte</h2>
          <p>
            Wir binden Funktions- und Inhaltselemente in unser Onlineangebot
            ein, die von den Servern ihrer jeweiligen Anbieter (nachfolgend als
            „Drittanbieter" bezeichnet) bezogen werden. Dabei kann es sich zum
            Beispiel um Grafiken, Videos oder Stadtpläne handeln (nachfolgend
            einheitlich als „Inhalte" bezeichnet).
          </p>
          <p>
            Die Einbindung setzt immer voraus, dass die Drittanbieter dieser
            Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne
            IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die
            IP-Adresse ist damit für die Darstellung dieser Inhalte oder
            Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu
            verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur
            Auslieferung der Inhalte anzuwenden. Drittanbieter können ferner
            sogenannte Pixel-Tags (unsichtbare Grafiken, auch als „Web Beacons"
            bezeichnet) für statistische oder Marketingzwecke einsetzen. Durch
            die „Pixel-Tags" können Informationen, wie etwa der Besucherverkehr
            auf den Seiten dieser Website, ausgewertet werden. Die pseudonymen
            Informationen können darüber hinaus in Cookies auf dem Gerät der
            Nutzer gespeichert werden und unter anderem technische Auskünfte zum
            Browser und zum Betriebssystem, zu verweisenden Websites, zur
            Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebots
            enthalten, aber auch mit solchen Informationen aus anderen Quellen
            verbunden werden.
          </p>
          <p>
            <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer
            um ihre Einwilligung in den Einsatz der Drittanbieter bitten, stellt
            die Rechtsgrundlage der Datenverarbeitung die Erlaubnis dar.
            Ansonsten werden die Nutzerdaten auf Grundlage unserer berechtigten
            Interessen (d. h. Interesse an effizienten, wirtschaftlichen und
            empfängerfreundlichen Leistungen) verarbeitet. In diesem
            Zusammenhang möchten wir Sie auch auf die Informationen zur
            Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.
          </p>
          <ul class="m-elements">
            <li>
              <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B.
              Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und
              -frequenz, verwendete Gerätetypen und Betriebssysteme,
              Interaktionen mit Inhalten und Funktionen). Meta-, Kommunikations-
              und Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
              Identifikationsnummern, beteiligte Personen).
            </li>
            <li>
              <strong>Betroffene Personen:</strong> Nutzer (z. B.
              Webseitenbesucher, Nutzer von Onlinediensten).
            </li>
            <li>
              <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
              Onlineangebotes und Nutzerfreundlichkeit.
            </li>
            <li>
              <strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend
              Angaben im Abschnitt "Allgemeine Informationen zur
              Datenspeicherung und Löschung". Speicherung von Cookies von bis zu
              2 Jahren (Sofern nicht anders angegeben, können Cookies und
              ähnliche Speichermethoden für einen Zeitraum von zwei Jahren auf
              den Geräten der Nutzer gespeichert werden.).
            </li>
            <li class="">
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S.
              1 lit. a) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit.
              f) DSGVO).
            </li>
          </ul>
          <p>
            <strong>
              Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
              Diensten:
            </strong>
          </p>
          <ul class="m-elements">
            <li>
              <strong>Google Fonts (Bezug vom Google Server): </strong>Bezug von
              Schriften (und Symbolen) zum Zwecke einer technisch sicheren,
              wartungsfreien und effizienten Nutzung von Schriften und Symbolen
              im Hinblick auf Aktualität und Ladezeiten, deren einheitliche
              Darstellung und Berücksichtigung möglicher lizenzrechtlicher
              Beschränkungen. Dem Anbieter der Schriftarten wird die IP-Adresse
              des Nutzers mitgeteilt, damit die Schriftarten im Browser des
              Nutzers zur Verfügung gestellt werden können. Darüber hinaus
              werden technische Daten (Spracheinstellungen, Bildschirmauflösung,
              Betriebssystem, verwendete Hardware) übermittelt, die für die
              Bereitstellung der Schriften in Abhängigkeit von den verwendeten
              Geräten und der technischen Umgebung notwendig sind. Diese Daten
              können auf einem Server des Anbieters der Schriftarten in den USA
              verarbeitet werden - Beim Besuch unseres Onlineangebotes senden
              die Browser der Nutzer ihre Browser HTTP-Anfragen an die Google
              Fonts Web API (d. h. eine Softwareschnittstelle für den Abruf der
              Schriftarten). Die Google Fonts Web API stellt den Nutzern die
              Cascading Style Sheets (CSS) von Google Fonts und danach die in
              der CCS angegebenen Schriftarten zur Verfügung. Zu diesen
              HTTP-Anfragen gehören (1) die vom jeweiligen Nutzer für den
              Zugriff auf das Internet verwendete IP-Adresse, (2) die
              angeforderte URL auf dem Google-Server und (3) die HTTP-Header,
              einschließlich des User-Agents, der die Browser- und
              Betriebssystemversionen der Websitebesucher beschreibt, sowie die
              Verweis-URL (d. h. die Webseite, auf der die Google-Schriftart
              angezeigt werden soll). IP-Adressen werden weder auf
              Google-Servern protokolliert noch gespeichert und sie werden nicht
              analysiert. Die Google Fonts Web API protokolliert Details der
              HTTP-Anfragen (angeforderte URL, User-Agent und Verweis-URL). Der
              Zugriff auf diese Daten ist eingeschränkt und streng kontrolliert.
              Die angeforderte URL identifiziert die Schriftfamilien, für die
              der Nutzer Schriftarten laden möchte. Diese Daten werden
              protokolliert, damit Google bestimmen kann, wie oft eine bestimmte
              Schriftfamilie angefordert wird. Bei der Google Fonts Web API muss
              der User-Agent die Schriftart anpassen, die für den jeweiligen
              Browsertyp generiert wird. Der User-Agent wird in erster Linie zum
              Debugging protokolliert und verwendet, um aggregierte
              Nutzungsstatistiken zu generieren, mit denen die Beliebtheit von
              Schriftfamilien gemessen wird. Diese zusammengefassten
              Nutzungsstatistiken werden auf der Seite „Analysen" von Google
              Fonts veröffentlicht. Schließlich wird die Verweis-URL
              protokolliert, sodass die Daten für die Wartung der Produktion
              verwendet und ein aggregierter Bericht zu den Top-Integrationen
              basierend auf der Anzahl der Schriftartenanfragen generiert werden
              kann. Google verwendet laut eigener Auskunft keine der von Google
              Fonts erfassten Informationen, um Profile von Endnutzern zu
              erstellen oder zielgerichtete Anzeigen zu schalten;
              <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon
              House, Barrow Street, Dublin 4, Irland;
              <span class="">
                <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art.
                6 Abs. 1 S. 1 lit. f) DSGVO);{" "}
              </span>
              <strong>Website:</strong>
              <a href="https://fonts.google.com/" target="_blank">
                https://fonts.google.com/
              </a>
              ; <strong>Datenschutzerklärung:</strong>
              <a href="https://policies.google.com/privacy" target="_blank">
                https://policies.google.com/privacy
              </a>
              ; <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
              Framework (DPF). <strong>Weitere Informationen:</strong>
              <a
                href="https://developers.google.com/fonts/faq/privacy?hl=de"
                target="_blank"
              >
                https://developers.google.com/fonts/faq/privacy?hl=de
              </a>
              .
            </li>
          </ul>
          <h2 id="m15">Änderung und Aktualisierung</h2>
          <p>
            Wir bitten Sie, sich regelmäßig über den Inhalt unserer
            Datenschutzerklärung zu informieren. Wir passen die
            Datenschutzerklärung an, sobald die Änderungen der von uns
            durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
            informieren Sie, sobald durch die Änderungen eine
            Mitwirkungshandlung Ihrerseits (z. B. Einwilligung) oder eine
            sonstige individuelle Benachrichtigung erforderlich wird.
          </p>
          <p>
            Sofern wir in dieser Datenschutzerklärung Adressen und
            Kontaktinformationen von Unternehmen und Organisationen angeben,
            bitten wir zu beachten, dass die Adressen sich über die Zeit ändern
            können und bitten die Angaben vor Kontaktaufnahme zu prüfen.
          </p>

          <h2 id="m42">Begriffsdefinitionen</h2>
          <p>
            In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
            Datenschutzerklärung verwendeten Begrifflichkeiten. Soweit die
            Begrifflichkeiten gesetzlich definiert sind, gelten deren
            gesetzliche Definitionen. Die nachfolgenden Erläuterungen sollen
            dagegen vor allem dem Verständnis dienen.
          </p>
          <ul class="glossary">
            <li>
              <strong>Bestandsdaten:</strong> Bestandsdaten umfassen wesentliche
              Informationen, die für die Identifikation und Verwaltung von
              Vertragspartnern, Benutzerkonten, Profilen und ähnlichen
              Zuordnungen notwendig sind. Diese Daten können u.a. persönliche
              und demografische Angaben wie Namen, Kontaktinformationen
              (Adressen, Telefonnummern, E-Mail-Adressen), Geburtsdaten und
              spezifische Identifikatoren (Benutzer-IDs) beinhalten.
              Bestandsdaten bilden die Grundlage für jegliche formelle
              Interaktion zwischen Personen und Diensten, Einrichtungen oder
              Systemen, indem sie eine eindeutige Zuordnung und Kommunikation
              ermöglichen.
            </li>
            <li>
              <strong>Inhaltsdaten:</strong> Inhaltsdaten umfassen
              Informationen, die im Zuge der Erstellung, Bearbeitung und
              Veröffentlichung von Inhalten aller Art generiert werden. Diese
              Kategorie von Daten kann Texte, Bilder, Videos, Audiodateien und
              andere multimediale Inhalte einschließen, die auf verschiedenen
              Plattformen und Medien veröffentlicht werden. Inhaltsdaten sind
              nicht nur auf den eigentlichen Inhalt beschränkt, sondern
              beinhalten auch Metadaten, die Informationen über den Inhalt
              selbst liefern, wie Tags, Beschreibungen, Autoreninformationen und
              Veröffentlichungsdaten
            </li>
            <li>
              <strong>Kontaktdaten:</strong> Kontaktdaten sind essentielle
              Informationen, die die Kommunikation mit Personen oder
              Organisationen ermöglichen. Sie umfassen u.a. Telefonnummern,
              postalische Adressen und E-Mail-Adressen, sowie
              Kommunikationsmittel wie soziale Medien-Handles und
              Instant-Messaging-Identifikatoren.
            </li>
            <li>
              <strong>Konversionsmessung:</strong> Die Konversionsmessung (auch
              als "Besuchsaktionsauswertung" bezeichnet) ist ein Verfahren, mit
              dem die Wirksamkeit von Marketingmaßnahmen festgestellt werden
              kann. Dazu wird im Regelfall ein Cookie auf den Geräten der Nutzer
              innerhalb der Webseiten, auf denen die Marketingmaßnahmen
              erfolgen, gespeichert und dann erneut auf der Zielwebseite
              abgerufen. Beispielsweise können wir so nachvollziehen, ob die von
              uns auf anderen Webseiten geschalteten Anzeigen erfolgreich waren.
            </li>
            <li>
              <strong>Meta-, Kommunikations- und Verfahrensdaten:</strong>{" "}
              Meta-, Kommunikations- und Verfahrensdaten sind Kategorien, die
              Informationen über die Art und Weise enthalten, wie Daten
              verarbeitet, übermittelt und verwaltet werden. Meta-Daten, auch
              bekannt als Daten über Daten, umfassen Informationen, die den
              Kontext, die Herkunft und die Struktur anderer Daten beschreiben.
              Sie können Angaben zur Dateigröße, dem Erstellungsdatum, dem Autor
              eines Dokuments und den Änderungshistorien beinhalten.
              Kommunikationsdaten erfassen den Austausch von Informationen
              zwischen Nutzern über verschiedene Kanäle, wie E-Mail-Verkehr,
              Anrufprotokolle, Nachrichten in sozialen Netzwerken und
              Chat-Verläufe, inklusive der beteiligten Personen, Zeitstempel und
              Übertragungswege. Verfahrensdaten beschreiben die Prozesse und
              Abläufe innerhalb von Systemen oder Organisationen, einschließlich
              Workflow-Dokumentationen, Protokolle von Transaktionen und
              Aktivitäten, sowie Audit-Logs, die zur Nachverfolgung und
              Überprüfung von Vorgängen verwendet werden.
            </li>
            <li>
              <strong>Nutzungsdaten:</strong> Nutzungsdaten beziehen sich auf
              Informationen, die erfassen, wie Nutzer mit digitalen Produkten,
              Dienstleistungen oder Plattformen interagieren. Diese Daten
              umfassen eine breite Palette von Informationen, die aufzeigen, wie
              Nutzer Anwendungen nutzen, welche Funktionen sie bevorzugen, wie
              lange sie auf bestimmten Seiten verweilen und über welche Pfade
              sie durch eine Anwendung navigieren. Nutzungsdaten können auch die
              Häufigkeit der Nutzung, Zeitstempel von Aktivitäten, IP-Adressen,
              Geräteinformationen und Standortdaten einschließen. Sie sind
              besonders wertvoll für die Analyse des Nutzerverhaltens, die
              Optimierung von Benutzererfahrungen, das Personalisieren von
              Inhalten und das Verbessern von Produkten oder Dienstleistungen.
              Darüber hinaus spielen Nutzungsdaten eine entscheidende Rolle beim
              Erkennen von Trends, Vorlieben und möglichen Problembereichen
              innerhalb digitaler Angebote
            </li>
            <li>
              <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten"
              sind alle Informationen, die sich auf eine identifizierte oder
              identifizierbare natürliche Person (im Folgenden "betroffene
              Person") beziehen; als identifizierbar wird eine natürliche Person
              angesehen, die direkt oder indirekt, insbesondere mittels
              Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer,
              zu Standortdaten, zu einer Online-Kennung (z. B. Cookie) oder zu
              einem oder mehreren besonderen Merkmalen identifiziert werden
              kann, die Ausdruck der physischen, physiologischen, genetischen,
              psychischen, wirtschaftlichen, kulturellen oder sozialen Identität
              dieser natürlichen Person sind.
            </li>
            <li>
              <strong>Profile mit nutzerbezogenen Informationen:</strong> Die
              Verarbeitung von "Profilen mit nutzerbezogenen Informationen",
              bzw. kurz "Profilen" umfasst jede Art der automatisierten
              Verarbeitung personenbezogener Daten, die darin besteht, dass
              diese personenbezogenen Daten verwendet werden, um bestimmte
              persönliche Aspekte, die sich auf eine natürliche Person beziehen
              (je nach Art der Profilbildung können dazu unterschiedliche
              Informationen betreffend die Demographie, Verhalten und
              Interessen, wie z. B. die Interaktion mit Webseiten und deren
              Inhalten, etc.) zu analysieren, zu bewerten oder, um sie
              vorherzusagen (z. B. die Interessen an bestimmten Inhalten oder
              Produkten, das Klickverhalten auf einer Webseite oder den
              Aufenthaltsort). Zu Zwecken des Profilings werden häufig Cookies
              und Web-Beacons eingesetzt.
            </li>
            <li>
              <strong>Protokolldaten:</strong> Protokolldaten sind Informationen
              über Ereignisse oder Aktivitäten, die in einem System oder
              Netzwerk protokolliert wurden. Diese Daten enthalten
              typischerweise Informationen wie Zeitstempel, IP-Adressen,
              Benutzeraktionen, Fehlermeldungen und andere Details über die
              Nutzung oder den Betrieb eines Systems. Protokolldaten werden oft
              zur Analyse von Systemproblemen, zur Sicherheitsüberwachung oder
              zur Erstellung von Leistungsberichten verwendet.
            </li>
            <li>
              <strong>Reichweitenmessung:</strong> Die Reichweitenmessung (auch
              als Web Analytics bezeichnet) dient der Auswertung der
              Besucherströme eines Onlineangebotes und kann das Verhalten oder
              Interessen der Besucher an bestimmten Informationen, wie z. B.
              Inhalten von Webseiten, umfassen. Mit Hilfe der Reichweitenanalyse
              können Betreiber von Onlineangeboten z. B. erkennen, zu welcher
              Zeit Nutzer ihre Webseiten besuchen und für welche Inhalte sie
              sich interessieren. Dadurch können sie z. B. die Inhalte der
              Webseiten besser an die Bedürfnisse ihrer Besucher anpassen. Zu
              Zwecken der Reichweitenanalyse werden häufig pseudonyme Cookies
              und Web-Beacons eingesetzt, um wiederkehrende Besucher zu erkennen
              und so genauere Analysen zur Nutzung eines Onlineangebotes zu
              erhalten.
            </li>
            <li>
              <strong>Tracking:</strong> Vom "Tracking" spricht man, wenn das
              Verhalten von Nutzern über mehrere Onlineangebote hinweg
              nachvollzogen werden kann. Im Regelfall werden im Hinblick auf die
              genutzten Onlineangebote Verhaltens- und Interessensinformationen
              in Cookies oder auf Servern der Anbieter der Trackingtechnologien
              gespeichert (sogenanntes Profiling). Diese Informationen können
              anschließend z. B. eingesetzt werden, um den Nutzern Werbeanzeigen
              anzuzeigen, die voraussichtlich deren Interessen entsprechen.
            </li>
            <li>
              <strong>Verantwortlicher:</strong> Als "Verantwortlicher" wird die
              natürliche oder juristische Person, Behörde, Einrichtung oder
              andere Stelle, die allein oder gemeinsam mit anderen über die
              Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
              entscheidet, bezeichnet.
            </li>
            <li>
              <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder
              ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede
              solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten.
              Der Begriff reicht weit und umfasst praktisch jeden Umgang mit
              Daten, sei es das Erheben, das Auswerten, das Speichern, das
              Übermitteln oder das Löschen.
            </li>
            <li>
              <strong>Zielgruppenbildung:</strong> Von Zielgruppenbildung
              (englisch "Custom Audiences") spricht man, wenn Zielgruppen für
              Werbezwecke, z. B. Einblendung von Werbeanzeigen bestimmt werden.
              So kann z. B. anhand des Interesses eines Nutzers an bestimmten
              Produkten oder Themen im Internet geschlussfolgert werden, dass
              dieser Nutzer sich für Werbeanzeigen für ähnliche Produkte oder
              den Onlineshop, in dem er die Produkte betrachtet hat,
              interessiert. Von "Lookalike Audiences" (bzw. ähnlichen
              Zielgruppen) spricht man wiederum, wenn die als geeignet
              eingeschätzten Inhalte Nutzern angezeigt werden, deren Profile,
              bzw. Interessen mutmaßlich den Nutzern zu denen die Profile
              gebildet wurden, entsprechen. Zur Zwecken der Bildung von Custom
              Audiences und Lookalike Audiences werden im Regelfall Cookies und
              Web-Beacons eingesetzt.
            </li>
          </ul>
        </div>
        <br /> <br />
      </div>
    </>
  );
}
