import { MyHeading } from "../Komponenten/MyHeading";
import { useEffect } from "react";
import { FAQ } from "../Komponenten/FAQ";
import Ich_hab_noch_nie_Fragen_Peinlich from "../images/Ich_hab_noch_nie_Fragen_Peinlich.avif";
import Ich_hab_noch_nie_Fragen_18 from "../images/Ich_hab_noch_nie_Fragen_18+.avif";
import Ich_hab_noch_nie_Fragen_Regeln from "../images/Ich_hab_noch_nie_Fragen_Regeln.avif";
import Ich_hab_noch_nie_Fragen_Junggesellinnenabschied from "../images/Ich_hab_noch_nie_Fragen_Junggesellinnenabschied.avif";

export function Startseite() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9733688669062561";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    const adScript = document.createElement("script");
    adScript.innerHTML = `(adsbygoogle = window.adsbygoogle || []).push({});`;
    document.body.appendChild(adScript);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(adScript);
    };
  }, []);

  return (
    <>
      <div className="box">
        <span className="description">
          <div className="passage">
            <section>
              <h2>
                Ich hab noch nie: Das ultimative Party und Trinkspiel für
                lustige, peinliche und freche Momente
              </h2>
              <br />
              <div className="row">
                <div className="column1">
                  <nav className="Anchorlinknav">
                    <ul>
                      <li className="anchorli">
                        <a href="#Regeln">Zu den Regeln</a>
                      </li>
                      <li className="anchorli">
                        <a href="#Lustig">Lustige Fragen</a>
                      </li>
                      <li className="anchorli">
                        <a href="#Peinlich">Peinliche Fragen</a>
                      </li>
                      <li className="anchorli">
                        <a href="#18+">18+ Fragen</a>
                      </li>
                      <li className="anchorli">
                        <a href="#Tipps">Tipps für das Spiel</a>
                      </li>
                      <li className="anchorli">
                        <a href="#Orte">Die besten Orte für das Spiel</a>
                      </li>
                      <li className="anchorli">
                        <a href="#Fazit">Fazit</a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="column2">
                  <img
                    className="seopics"
                    src={Ich_hab_noch_nie_Fragen_Junggesellinnenabschied}
                    alt="Personen, bereit für eine Runde 'Ich hab noch nie'"
                  />
                </div>
                <div className="column"></div>
              </div>
              <br />
              <p>
                Du suchst nach dem perfekten Eisbrecher für deine nächste Party?
                Oder willst du einfach einen unterhaltsamen Abend mit Freunden
                verbringen? Dann bist du hier genau richtig! Unsere kostenlose
                Browser-App für das Kultspiel "Ich hab noch nie" liefert dir
                endlose Fragen für unvergessliche Spielrunden - ganz ohne
                Anmeldung und direkt im Browser spielbar. Ob du nach lustigen
                "Ich hab noch nie"-Fragen, peinlichen Geständnissen oder
                pikanten 18+ Varianten suchst: Hier findest du für jeden
                Geschmack und jede Altersgruppe die passenden Fragen. Lass uns
                gemeinsam in die Welt dieses beliebten Partyspiels eintauchen
                und entdecken, warum "Ich hab noch nie" der perfekte
                Zeitvertreib für Jugendliche und junge Erwachsene ist.
              </p>
              <br />
            </section>
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9733688669062561"
              crossOrigin="anonymous"
            ></script>
            <ins
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client="ca-pub-9733688669062561"
              data-ad-slot="9134512776"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
            <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
            <section id="Regeln">
              <h3>Die Regeln: So funktioniert "Ich hab noch nie"</h3>
              <br />
              <div className="row">
                <div className="column2">
                  <img
                    className="seopics"
                    src={Ich_hab_noch_nie_Fragen_Regeln}
                    alt="Person die dafür sorgt, dass Regeln eingehalten werden"
                  />
                </div>
                <div className="spacer"></div>
                <div className="column1">
                  <p>
                    Bevor wir in die verschiedenen Fragenkategorien eintauchen,
                    klären wir kurz die Regeln. Keine Sorge, "Ich hab noch nie"
                    ist super einfach zu verstehen und zu spielen:
                  </p>
                  <br />
                  <ol>
                    <li>
                      Alle Spieler sitzen im Kreis. <br /> <br />
                    </li>
                    <li>
                      Der erste Spieler beginnt mit einer Aussage: "Ich hab noch
                      nie..."
                      <br /> <br />
                    </li>
                    <li>
                      Alle Mitspieler, die diese Handlung schon einmal gemacht
                      haben, müssen einen Schluck trinken oder einen Finger
                      senken (je nach Variante).
                      <br /> <br />
                    </li>
                    <li>
                      Wer als Letzter noch Finger übrig hat oder am wenigsten
                      getrunken hat, gewinnt.
                      <br /> <br />
                    </li>
                  </ol>
                  <br />
                  <p>
                    Das Beste daran? Mit unserer kostenlosen Browser-App
                    brauchst du nicht einmal Zettel und Stift. Wir liefern dir
                    unendlich viele Fragen für "Ich hab noch nie", sodass ihr
                    stundenlang Spaß haben könnt.
                  </p>
                  <br />
                </div>
              </div>
            </section>
            <section id="Lustig">
              <h3>
                Lustige "Ich hab noch nie"-Fragen: Für Lachkrämpfe garantiert
              </h3>
              <div className="row">
                <div className="spacer"></div>
                <div className="column">
                  <br />
                  <p>
                    Beginnen wir mit den Klassikern: lustige Fragen für "Ich hab
                    noch nie", die garantiert für Heiterkeit in der Runde
                    sorgen. Diese Fragen sind perfekt, um das Eis zu brechen und
                    alle Mitspieler zum Lachen zu bringen:
                  </p>
                  <br />
                  <ul>
                    <li>
                      Ich hab noch nie in der Öffentlichkeit laut gelacht?
                    </li>
                    <li>
                      Ich hab noch nie aus Versehen etwas Peinliches gesagt?
                    </li>
                    <li>Ich hab noch nie im Schlaf gesprochen?</li>
                    <li>
                      Ich hab noch nie heimlich die Zahnbürste von jemand
                      anderem benutzt?
                    </li>
                  </ul>
                  <br />
                  <p>
                    Diese Art von Fragen sorgt nicht nur für gute Stimmung,
                    sondern offenbart auch lustige Geschichten und Anekdoten.
                    Wer hätte gedacht, dass dein bester Kumpel mal eine ganze
                    Woche lang seine Hose falsch herum getragen hat, ohne es zu
                    merken?
                  </p>
                  <br />
                </div>
                <h3 id="Peinlich">
                  Peinliche "Ich hab noch nie"-Fragen: Wenn die Gesichter rot
                  werden
                </h3>
                <br />
                <br />
                <div className="column2">
                  {" "}
                  <img
                    className="seopics"
                    src={Ich_hab_noch_nie_Fragen_Peinlich}
                    alt="Person die eine Peinliche Ich hab noch nie Frage beantworten muss"
                  />
                </div>
                <div className="spacer"></div>
                <div className="column1">
                  <p>
                    Jetzt wird's interessant! Peinliche Fragen bei "Ich hab noch
                    nie" bringen oft die besten Reaktionen hervor. Hier ein paar
                    Beispiele für Fragen, die garantiert für rote Köpfe sorgen:
                  </p>
                  <br />
                  <ul>
                    <li>
                      Ich hab noch nie aus Versehen jemanden geküsst, den ich
                      eigentlich nicht küssen wollte?
                    </li>
                    <li>
                      Ich hab noch nie eine peinliche Nachricht an die falsche
                      Person geschickt?
                    </li>
                    <li>
                      Ich hab noch nie beim Lügen erwischt und mir eine noch
                      größere Lüge ausgedacht, um die erste zu vertuschen?
                    </li>
                  </ul>
                  <br />
                  <p>
                    Diese Fragen zeigen, dass peinliche Momente zum Leben
                    dazugehören und jeder schon mal in unangenehme Situationen
                    geraten ist. Das Teilen solcher Erlebnisse kann sogar
                    verbinden und neue Freundschaften stärken.
                  </p>
                  <br />
                </div>
                <h3 id="18+">
                  18+ Fragen für "Ich hab noch nie": Wenn es heiß hergeht
                </h3>
                <br />
                <br />
                <div className="column2">
                  <img
                    className="seopics"
                    src={Ich_hab_noch_nie_Fragen_18}
                    alt="Person die sich eine 18+ ich hab noch nie Frage ausdenkt"
                  />
                </div>
                <div className="spacer"></div>
                <div className="column1">
                  <p>
                    Achtung, jetzt wird's pikant! Die 18+ Variante von "Ich hab
                    noch nie" ist definitiv nichts für schwache Nerven oder
                    prüde Gemüter. Hier ein paar Beispiele für Fragen, die das
                    Spiel auf die nächste Stufe heben:
                  </p>
                  <br />
                  <ul>
                    <li>Ich hab noch nie einen One-Night-Stand gehabt?</li>
                    <li>Ich hab noch nie beim Sex gelacht oder geweint?</li>
                    <li>Ich hab noch nie meine:n Ex gestalked?</li>
                    <li>Ich bin noch nie beim Sex erwischt worden?</li>
                  </ul>
                  <br />
                  <p>
                    Wichtig: Stellt sicher, dass alle Mitspieler mit dieser Art
                    von Fragen einverstanden sind. Respektiert die Grenzen
                    anderer und erinnert euch daran, dass niemand gezwungen ist,
                    Details preiszugeben, bei denen er sich unwohl fühlt.
                  </p>
                  <br />
                </div>
              </div>
            </section>
            <section id="Tipps">
              <h3>
                Tipps und Tricks für das perfekte "Ich hab noch nie"-Spiel
              </h3>
              <br />
              <p>
                Um euer "Ich hab noch nie"-Erlebnis zu optimieren, haben wir ein
                paar Profi-Tipps für euch:
              </p>
              <br />
              <ul>
                <li>
                  Mischt die Fragenkategorien: Wechselt zwischen lustigen,
                  peinlichen und (falls gewünscht) 18+ Fragen ab, um das Spiel
                  abwechslungsreich zu gestalten.
                  <br />
                  <br />
                </li>
                <li>
                  Setzt einen Timer: Manchmal können die Geschichten zu
                  einzelnen Fragen ausufern. Ein Timer von 1-2 Minuten pro Runde
                  hält das Spiel in Schwung.
                  <br />
                  <br />
                </li>
                <li>
                  Nutzt Joker: Jeder Spieler darf einmal pro Spiel eine Frage
                  überspringen, wenn sie ihm zu unangenehm ist.
                  <br /> <br />
                </li>
                <li>
                  Storytelling erwünscht: Ermutigt die Spieler, lustige Details
                  zu ihren Erlebnissen zu erzählen. Das macht das Spiel noch
                  unterhaltsamer.
                  <br /> <br />
                </li>
                <li>
                  Passt die Regeln an: Ihr könnt statt Alkohol auch Softdrinks
                  verwenden oder statt Fingern Spielsteine einsetzen. Seid
                  kreativ!
                  <br /> <br />
                </li>
                <li>
                  Nutzt unsere Browser-App: Mit der kostenlosen App habt ihr
                  Zugriff auf unendlich viele Fragen und müsst euch keine
                  eigenen ausdenken.
                  <br /> <br />
                </li>
              </ul>
            </section>
            <section id="Orte">
              <h3>Die besten Orte zum Spielen von "Ich hab noch nie"</h3>
              <br />
              <p>
                "Ich hab noch nie" ist so vielseitig, dass es fast überall
                gespielt werden kann. Hier sind einige der besten Gelegenheiten
                und Orte für "Ich hab noch nie":
              </p>
              <br />
              <ul>
                <li>
                  Hausparties: Der Klassiker! Gemütlich auf der Couch oder am
                  Küchentisch ist "Ich hab noch nie" der perfekte Eisbrecher.
                </li>
                <li>
                  Festivals und Campingplätze: In lockerer Atmosphäre neue Leute
                  kennenlernen? "Ich hab noch nie" macht's möglich!
                </li>
                <li>
                  Junggesellenabschiede: Erfahrt mehr über den Bräutigam oder
                  die Braut, als ihr je wissen wolltet.
                </li>
                <li>
                  Klassenfahrten oder Studienreisen: Bringt Schwung in
                  langweilige Abende im Schullandheim.
                </li>
                <li>
                  Online mit Freunden: Dank unserer Browser-App könnt ihr auch
                  digital mit Freunden spielen - perfekt für Lockdown-Zeiten
                  oder Fernbeziehungen.
                </li>
                <li>
                  Kennenlern-Runden: Ob in der Uni, beim Teambuilding oder in
                  der neuen WG - "Ich hab noch nie" bringt Menschen zusammen.
                </li>
              </ul>
              <br />
              <h3 id="Fazit">
                Fazit: Warum "Ich hab noch nie" das ultimative Partyspiel ist
              </h3>
              <br />
              <p>
                "Ich hab noch nie" ist mehr als nur ein simples Trinkspiel. Es
                ist eine Möglichkeit, neue Seiten an Freunden zu entdecken, über
                sich selbst zu lachen und unvergessliche Momente zu schaffen.
                Von harmlosen, lustigen Fragen bis hin zu peinlichen
                Geständnissen und pikanten 18+ Varianten - dieses Spiel hat für
                jeden etwas zu bieten.
              </p>
              <br />
              <p>
                Mit unserer kostenlosen Browser-App für "Ich hab noch
                nie"-Fragen habt ihr immer und überall Zugriff auf endlose
                Spielrunden. Keine lästige Anmeldung, kein Download - einfach
                den Browser öffnen und losspielen. Ob ihr nach Fragen für "Ich
                hab noch nie" sucht, Inspirationen für peinliche Geständnisse
                braucht oder einfach Lust auf ein unterhaltsames Spiel mit
                Freunden habt - Ihr seid bestens gerüstet.
              </p>
              <br />
              <p>
                Also, worauf wartet ihr noch? Ladet eure Freunde ein, öffnet
                unsere App und taucht ein in die Welt von "Ich hab noch nie".
                Wer weiß, vielleicht entdeckt ihr dabei nicht nur Überraschendes
                über eure Mitspieler, sondern auch über euch selbst. Eines ist
                sicher: Lachkrämpfe, rote Köpfe und jede Menge Spaß sind
                vorprogrammiert!
              </p>
              <br />
            </section>
          </div>
        </span>
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-9733688669062561"
          data-ad-slot="9134512776"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </div>
    </>
  );
}
